import { useState } from "react";

// Sample project data
const projects = [
  {
    title: "BodyClinix",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1726482279/mcqvih9alqsavttem7z3.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728640990/djkp7zlcbhwfv8atzaiq.png",
    liveSite: "https://bodyclinix.org/",
  },
  {
    title: "Aramex",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643888/cmn3hmefrxtraiamnu9z.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643839/wbsl5w5usvsko9akahte.png",
    liveSite: "https://www.aramex.com/in/en",
  },
  {
    title: "Happy Mama Tales",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1726482279/ihgqys4sws6ajolwvam2.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643836/obc3lgle73se5cvkai2g.png",
    liveSite: "https://happymamatales.com/",
  },
  {
    title: "Healing Horse Ranch",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643888/rtwlcmpkx59lcg0yytqr.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643843/uapjmgchffhmo4qnxm9j.png",
    liveSite: "https://www.healinghorsesranch.net/",
  },
  {
    title: "Inside Interio",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643888/c8ctwkx4quwcrbkh1wnl.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643840/z95vfum8p5z550uhmfjy.png",
    liveSite: "https://interiordesign-6fef6.web.app/",
  },
  {
    title: "Junas Cafe",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643887/spjntvx5qeuygl0hmgfc.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1728643840/blf1sewchlcb9ykpy3n0.png",
    liveSite: "https://junas-cafe.web.app/",
  },
  {
    title: "GrandHotel",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1730808706/hdaxgktbctoz0ax9llfc.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1730808706/hdaxgktbctoz0ax9llfc.png",
    liveSite: "https://grandhotel123.netlify.app/",
  },
  {
    title: "Gymate",
    image:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1730808705/bokvsuomnf64urrzeugu.png",
    longScreenshot:
      "https://res.cloudinary.com/dby6tugob/image/upload/v1730808705/bokvsuomnf64urrzeugu.png",
    liveSite: "https://thegymate.netlify.app/",
  },
];

const ProjectCard = ({ project }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <a
      href={project.liveSite}
      target="_blank"
      rel="noopener noreferrer"
      className="block"
    >
      <div
        className="relative overflow-hidden bg-white shadow-md rounded-lg hover:shadow-xl transition-shadow duration-300"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {/* Project Image */}
        <div className="relative h-64 overflow-hidden">
          <img
            src={hovering ? project.longScreenshot : project.image}
            alt={project.title}
            className={`h-full w-full object-cover transition-transform duration-500 ${
              hovering ? "scale-105" : ""
            }`}
          />
          {/* Scrolling Screenshot */}
          {hovering && (
            <div className="absolute inset-0 overflow-hidden">
              <div className="h-full w-full">
                <img
                  src={project.longScreenshot}
                  alt={`${project.title} Full Screenshot`}
                  className="h-[200%] animate-scroll-website"
                />
              </div>
            </div>
          )}
        </div>

        {/* Project Details */}
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-900 hover:underline hover:text-blue-600 text-center ">
            {project.title}
          </h3>
          {/* <p className="text-gray-400 text-sm">{project.description}</p> */}
        </div>
      </div>
    </a>
  );
};

const ProjectsSection = () => {
  return (
    <div className="container mx-auto p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      ))}
    </div>
  );
};

export default ProjectsSection;
