import React, { useState } from "react";
import { LPAddContact } from "../helpers";
import toast from 'react-hot-toast'

const GetQuoteSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!name || !email || !phoneNumber || !message) {
     toast.error("Please fill in all required fields.")
      setLoading(false);
      return;
    }

    const data = {
      name,
      email,
      phone: phoneNumber,
      message,
    };

    try {
      const res = await LPAddContact(data);
      
      // Show success toast
      toast.success('Successfully submitted!');

      // Clear form fields
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      
    } catch (err) {
      console.error(err);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Something went wrong. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="relative min-h-screen py-32">
      {/* Image Wrapper with Overlay */}
      <div className="absolute inset-0">
        <div className="relative h-full w-full bg-cover bg-center" style={{ backgroundImage: 'url("./landingPageImages/banner-2.jpg")' }}>
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </div>

      <div className="relative container mx-auto flex flex-col md:flex-row justify-between items-center py-12">
        
        {/* Left Section - Text Content */}
        <div className="text-white max-w-xl p-8 z-10">
          <h1 className="text-4xl font-bold mb-4 md:text-5xl lg:text-6xl">READY TO GROW YOUR BUSINESS?</h1>
          <p className="text-xl mb-6">Contact us to work with a results-driven digital marketing agency.</p>
          <button className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 focus:outline-none">
             Call Now
          </button>
        </div>

        {/* Right Section - Form */}
        <div className="relative bg-zinc-950 shadow-lg rounded-lg p-8 max-w-md w-full z-10">
          <h2 className="text-2xl font-bold mb-4 text-white">Get a Free Quote</h2>
          <p className="text-gray-400 mb-6">We are here to help you 24/7.</p>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
            <input
              type="email"
              placeholder="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
            <textarea
              placeholder="Your Message Here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              rows="4"
              required
            ></textarea>

            <button
              className="w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 focus:outline-none disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Now"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default GetQuoteSection;