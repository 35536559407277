// import { Canvas } from "@react-three/fiber";
import "./style.css";
// import { OrbitControls } from "@react-three/drei";
// import Scene from "./Scene.jsx";
// import { Bloom, EffectComposer } from "@react-three/postprocessing";
import Navbar from "./Navbar.jsx";
import Marquee from "./Marquee.jsx";
import HoverImageLinks from "./Services.jsx";
// import RevealBento from "./RevealBento.jsx";
import Footer from "./Footer.jsx";
import Contact from "./Contact.jsx";
import Features from "./Features.jsx";
import SocialMediaLinks from "./SocialMediaLinks.jsx";
import FollowUs from "./FollowUs.jsx";
import ContactDetails from "./ContactDetails.jsx";
import { useEffect } from "react";
// import LandingPage from "./LandingPage.jsx";
import Projects from "./Projects.jsx";
import GetQuoteSection from "./GetQuoteSection.jsx";
import Icon from '../components/Icon/Icon.jsx'
import Map from "./Map.jsx";
import { Toaster } from "react-hot-toast";

const LandingAppPage = () => {
  useEffect(() => {
    (async () => {
      const LocomotiveScroll = (await import("locomotive-scroll")).default;
      const locomotiveScroll = new LocomotiveScroll();
    })();
  }, []);

  return (
    <div className="bg-black overflow-hidden">
      <Navbar />

      {/* <Canvas flat camera={{ fov: 25 }}> */}
        {/* <OrbitControls /> */}
        {/* <ambientLight /> */}
        {/* <Scene /> */}

        {/* <EffectComposer> */}
          {/* < */}
            {/* mipmapBlur
            intensity={3.0} // The bloom intensity.
            luminanceThreshold={0.2} // luminance threshold. Raise this value to mask out darker elements in the scene.
            luminanceSmoothing={0} // smoothness of the luminance threshold. */}
          {/* /> */}
          {/* <ToneMapping adaptive /> */}
        {/* </EffectComposer> */}
      {/* </Canvas> */}

      {/*  */}
      <GetQuoteSection/>
      {/* <LandingPage /> */}
      <Marquee />
      <HoverImageLinks />
      <Features />
      <Projects />

      <FollowUs />
      {/* <SocialMediaLinks /> */}
      <ContactDetails />
      {/* <Contact /> */}
      <Map/>
      <Footer />
      <Icon/>
      <Toaster/>
    </div>
  );
};

export default LandingAppPage;
