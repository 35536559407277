import React, { useEffect } from "react";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import pfOne from "../../assests/portfolioImageCollection/pf1.png";
import pfTwo from "../../assests/portfolioImageCollection/pf2.png";
import pfThree from "../../assests/portfolioImageCollection/pf3.png";
import pfFour from "../../assests/portfolioImageCollection/pf4.png";
import pfFive from "../../assests/portfolioImageCollection/pf5.png";
import pfSix from "../../assests/portfolioImageCollection/pf6.png";
import pfSeven from "../../assests/portfolioImageCollection/pf7.png";
import pfEight from "../../assests/portfolioImageCollection/pf8.png";
import pfNine from "../../assests/portfolioImageCollection/pf9.png";
import pfTen from "../../assests/portfolioImageCollection/pf10.png";
import pfElleven from "../../assests/portfolioImageCollection/pf11.png";
import pfTwelve from "../../assests/portfolioImageCollection/pf12.png";
import pfThirteen from "../../assests/portfolioImageCollection/pf13.png";
import pfFourteen from "../../assests/portfolioImageCollection/pf14.png";
import pfFifteen from "../../assests/portfolioImageCollection/pf15.png";
import pfSixteen from "../../assests/portfolioImageCollection/pf16.png";
import pfSeventeen from "../../assests/portfolioImageCollection/pf17.png";
import pfEighteen from "../../assests/portfolioImageCollection/pf18.png";
import pfNineteen from "../../assests/portfolioImageCollection/pf19.png";
import Icon from "../../components/Icon/Icon";

const Portfolio = () => {
  useEffect(() => {
    document.title = "Portfolio - Webwise Digital";
  }, []);

  // Array of project details
  const projects = [
    {
      id: 1,
      title: "Aramex",
      category: "Website",
      imageUrl: pfOne,
      link: "https://www.aramex.com/in/en",
    },
    {
      id: 2,
      title: "Arig",
      category: "Website",
      imageUrl: pfTwo,
      link: "https://www.arig.net/",
    },
    {
      id: 3,
      title: "nftssouvenir",
      category: "Social Media Managment",
      imageUrl: pfThree,
      link: "https://www.instagram.com/nftssouvenir/",
    },
    {
      id: 4,
      title: "Ace Furnishings",
      category: "Web Development",
      imageUrl: pfFour,
      link: "https://www.acefurnishings.in",
    },
    {
      id: 5,
      title: "Happy Mama Tales",
      category: "Web Development",
      imageUrl: pfFive,
      link: "https://happymamatales.com/",
    },
    {
      id: 6,
      title: "Healing Horses Ranch",
      category: "Web Development",
      imageUrl: pfSix,
      link: "https://www.healinghorsesranch.net/",
    },
    {
      id: 7,
      title: "Gumbad Cafe",
      category: "Social Media Management",
      imageUrl: pfSeven,
      link: "https://www.instagram.com/gumbad.cafe/",
    },
    {
      id: 8,
      title: "Dr.Shwetanjali’s Makeup Studio",
      category: "Logo Design",
      imageUrl: pfEight,
    },
    {
      id: 9,
      title: "Ten Working Fingers",
      category: "Logo Design",
      imageUrl: pfNine,
    },
    {
      id: 10,
      title: "Sparkling Occasions",
      category: "Social Media Management",
      imageUrl: pfTen,
      link: "https://www.instagram.com/sparklingoccasionssvproduction/",
    },
    {
      id: 11,
      title: "Slashcutfilms",
      category: "Social Media Management",
      imageUrl: pfElleven,
      link: "https://www.instagram.com/Slashcutfilms/",
    },
    {
      id: 12,
      title: "Knots and Collar",
      category: "Social Media Management",
      imageUrl: pfTwelve,
      link: "https://www.instagram.com/knotsandcollar/",
    },
    {
      id: 13,
      title: "Alive and Wellness clinic (30k Followers)",
      category: "Social Media Management",
      imageUrl: pfThirteen,
      link: "https://www.instagram.com/alivewellnessclinics/",
    },
    {
      id: 14,
      title: "Interior Design Sample Project",
      category: "Web Devlopment",
      imageUrl: pfFourteen,
      link: "https://interiordesign-6fef6.web.app/",
    },
    {
      id: 15,
      title: "Junas Cafe",
      category: "Web Devlopment",
      imageUrl: pfFifteen,
      link: "https://junas-cafe.web.app/",
    },
    {
      id: 16,
      title: "Travel Website",
      category: "Web Devlopment",
      imageUrl: pfSixteen,
      link: "https://graceful-vacherin-e9d85e.netlify.app/",
    },
    {
      id: 17,
      title: "Flight Booking Website",
      category: "Web Devlopment",
      imageUrl: pfSeventeen,
      link: "https://melodious-druid-2634ca.netlify.app/",
    },
    {
      id: 18,
      title: "Gym Website",
      category: "Web Devlopment",
      imageUrl: pfEighteen,
      link: "https://thegymate.netlify.app/",
    },
    {
      id: 19,
      title: "Hotel Website",
      category: "Web Devlopment",
      imageUrl: pfNineteen,
      link: "https://grandhotel123.netlify.app/",
    },
  ];

  return (
    <>
      <motion.section
        className="bg-white dark:bg-gray-900 pt-32 font-poppins"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      >
        <Navbar/>
        <Icon/>
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-2xl font-bold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">
            Our Portfolio
          </h1>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 lg:grid-cols-2">
            {projects.map((project) => (
              <div
                key={project.id}
                className="flex items-end overflow-hidden bg-cover rounded-lg h-96 border-2 border-darkPink shadow-md shadow-pink-400"
                style={{ backgroundImage: `url(${project.imageUrl})` }}
              >
                <div className="w-full px-8 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm bg-dark">
                  <h2 className="mt-4 text-xl font-semibold text-gray-800 capitalize dark:text-white">
                    {project.title}
                  </h2>
                  <div className="flex flex-row items-center gap-6">
                    <p className="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-white">
                      Category: {project.category}
                    </p>
                    {project.link && (
                      <Link to={project.link} target="_blank">
                        <p className="mt-2 text-lg cursor-pointer tracking-wider text-blue-500 dark:text-blue-400">
                          Visit
                        </p>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer/>
      </motion.section>
    </>
  );
};

export default Portfolio;
