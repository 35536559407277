import React from "react";

function Map() {
  return (
    <section className="bg-neutral-950 text-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8 font-poppins">
        <div className="max-w-3xl lg:max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-gray-50">
            Visit Our Location
          </h2>
        </div>
        <div className="mt-16 lg:mt-20">
          <div className="grid grid-cols-1 md:grid-row-2 gap-8">
            <div className="rounded-lg overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d438.08956999765263!2d77.13250813223117!3d28.548238045422885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d2b913124f9%3A0xdd0a795105f06165!2sBeeGreen%20Plaza!5e0!3m2!1sen!2sin!4v1730976371941!5m2!1sen!2sin"
                width="100%"
                height="480"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div>
              <div className="grid max-w-full mx-auto rounded-lg overflow-hidden lg:grid-cols-2">
                <div className="px-6 py-4">
                  <h3 className="text-xl font-medium text-gray-100">
                    Our Address
                  </h3>
                  <p className="mt-1 text-gray-300">1016/2, Fourth Floor, Beegreen plaza, Mahipalpur,
                  New Delhi, Delhi 110037</p>
                </div>
                <div className="px-6 py-4">
                  <h3 className="text-xl font-medium text-gray-100">Contact</h3>

                  <p className="mt-1 text-gray-300">
                    Phone: +91-9953775715
                  </p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;