import React, { useRef } from "react";
// import { AiFillInstagram } from "react-icons/ai";
import { LiaInstagram } from "react-icons/lia";
import { ImLinkedin } from "react-icons/im";
import { FaFacebook } from "react-icons/fa";
import { motion, useScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";

const FollowUs = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });
  const x = useTransform(scrollYProgress, [0, 1], [0, -800]);

  return (
    <div
      ref={containerRef}
      className="bg-stone-950  text-white overflow-hidden py-9 font-poppins font-medium"
    >
      <motion.div
        style={{ x }}
        className="flex justify-around items-center text-[4rem] md:text-[8rem]"
      >
        <span className="flex shrink-0 space-x-1 items-center">
          Follow Us{" "}
          <Link to={'https://www.facebook.com/people/WebWise-Digital-Pvt-Ltd/61552903648554/'} target="_blank">
          <FaFacebook className="text-6xl md:text-8xl text-stone-300 mx-9" />
          </Link>
        </span>
        <span className="flex shrink-0 space-x-1 items-center">
          Follow Us{" "}
          <Link to={'https://www.linkedin.com/company/webwise-digital-pvt-ltd/'} target="_blank">
          <ImLinkedin className="text-[5rem] text-6xl md:text-8xl text-stone-300 mx-9 rounded-full" />
          </Link>
        </span>
        <span className="flex shrink-0 space-x-1 items-center">

          Follow Us 
          <Link to={'https://www.instagram.com/webwisedigitalpvtltd/'} target="_blank">
          <LiaInstagram className="text-8xl text-stone-300 mx-9" />
          </Link>
        </span>
        <span className="flex shrink-0 space-x-1 items-center">Follow Us</span>
      </motion.div>
    </div>
  );
};

export default FollowUs;
