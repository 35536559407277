import { useEffect } from "react";
import AboutBanner from "../../components/About/AboutBanner";
import Description from "../../components/About/Description";
import WhyUs from "../../components/About/WhyUs";

import { motion } from "framer-motion";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Icon from "../../components/Icon/Icon";

function About() {
  useEffect(() => {
    document.title = "About us - Webwise Digital";
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className="md:pt-2 lg:pt-4"
    >
      <Navbar/>
      <Icon/>
      <AboutBanner />
      <Description />
      <WhyUs />
      <Footer/>
    </motion.div>
  );
}

export default About;
