import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 fot">
      <p className="text-center text-zinc-400">
        Made with ❤️ by{" "}
        <a
          href="https://webwisedigital.in/"
          target="_blank"
          className="text-red-300 hover:underline"
        >
          @webwisedigital
        </a>
      </p>
    </footer>
  );
};

export default Footer;
