import React from "react";
import {
  FaCode,
  FaSearch,
  FaVideo,
  FaBehance,
  FaFigma,
  FaBullhorn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import BlueBgImage from "../../../assests/blue-bg.png";

const servicesData = [
  {
    icon: <FaCode />,
    title: "Web Development",
    description:
      "Custom websites tailored to your needs. Elevate your online presence.",
    delay: "0.1s",
    link: "/services/web-services",
  },
  {
    icon: <FaSearch />,
    title: "SEO Services",
    description:
      "Expert SEO solutions for better visibility. Boost your organic traffic.",
    delay: "0s",
    link: "/services/seo-services",
  },
  {
    icon: <FaVideo />,
    title: "Video Editing",
    description:
      "Professional video editing to engage your audience. Make your brand stand out.",
    delay: "0.3s",
    link: "/services/video-editing-services",
  },
  {
    icon: <FaBullhorn />,
    title: "Paid Ads",
    description:
      "Targeted advertising that drives results. Maximize your ROI effectively.",
    delay: "0s",
    link: "/services/paid-ads-services",
  },
  {
    icon: <FaBehance />,
    title: "Graphic Design",
    description:
      "Creative designs that represent your brand. One-stop solution for all your needs.",
    delay: "0.1s",
    link: "/services/graphic-design-services",
  },
  {
    icon: <FaFigma />,
    title: "UI/UX",
    description:
      "Intuitive design that enhances user experience. Bridging goals and user expectations.",
    delay: "0.3s",
    link: "/services/ui-and-ux",
  },
];

function Services() {
  return (
    <div
      id="services"
      className="section relative pt-20 pb-8 md:pt-16 md:pb-0 font-poppins bg-white"
      style={{
        backgroundImage: `url(${BlueBgImage})`,
        backgroundSize: "100% auto", // Scale width to screen width, height adjusts automatically
        backgroundPosition: "center 120px", // Shift background image 200px down
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
        width: "100vw", // Full screen width
        minHeight: "100vh", // Set the div height to at least the height of the viewport
        position: "relative", // Ensure the content inside is positioned relative to the background
      }}
    >
      <div className="container xl:max-w-6xl mx-auto px-4">
        <header className="text-center mx-auto mb-12 lg:px-20">
          <h2 className="text-3xl leading-normal mb-2 font-bold text-black md:text-4xl lg:text-6xl">
            What We Do
          </h2>
          <p className="text-black leading-relaxed font-normal text-xl mx-auto pb-2">
            Empowering your digital presence with tailored solutions in web
            development, SEO, creative design, and more.
          </p>
        </header>
        <div className="flex flex-wrap flex-row -mx-4 text-center mt-28">
          {servicesData.map((service, index) => (
            <div
              key={index}
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 mb-6"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: service.delay,
                animationName: "fadeInUp",
              }}
            >
              <Link to={service.link}>
                <div className="flex flex-col gap-6 h-full w-full bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-0 border border-gray-100 border-opacity-55">
                  <div className="p-3 flex flex-row items-center gap-4">
                    <div className="w-10 h-10 bg-blue-500 rounded-full text-white flex items-center justify-center text-2xl">
                      {service.icon}
                    </div>
                    <h1 className="text-black text-xl font-semibold">
                      {service.title}
                    </h1>
                  </div>
                  <div className="text-black font-light text-left p-3">
                    {service.description}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
