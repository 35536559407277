import React from "react";
import ProjectCard from "./ProjectCard";

const Projects = () => {
  return (
    <div className="bg-zinc-900 text-white h-fit pt-2 md:pt-0 pb-0 md:pb-32 px-6 md:px-32 md:rounded-t-[4rem] font-poppins">
      <div className="text-4xl md:text-5xl lg:text-7xl py-14 md:py-28 font-bold">
        <div>Featured Project<span className="text-green-500">.</span></div>
        {/* <div className="flex items-center md:items-end my-1">
          <div className="h-16 md:h-28 w-20 md:w-40 rounded-full overflow-hidden">
            <video
              src="https://res.cloudinary.com/dby6tugob/video/upload/v1725368317/okup3vsuczn6pbknb8z6.mp4"
              className="mt-2"
              loop
              autoPlay
              muted
            ></video>
          </div>
          <div>
            <span
              id="design"
              className=" tracking-wide text-[3.2rem] md:text-[8.5rem] font-bold"
            >
              &nbsp;projects
            </span>
          </div>
        </div> */}
      </div>
      <ProjectCard />
    </div>
  );
};

export default Projects;
