import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";

const Navbar = () => {
  const links = [
    { name: "Services", url: "https://webwisedigital.in/services" },
    { name: "About Us", url: "https://webwisedigital.in/aboutus" },
    { name: "Contact Us", url: "https://webwisedigital.in/contactus" },
    { name: "Portfolio", url: "https://webwisedigital.in/portfolio" },
  ];

  const [scrollDirection, setScrollDirection] = useState("none");
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > prevScrollPos) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    // Disable body scroll when menu is open
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [menuOpen]);

  const isHidden = scrollDirection === "down";

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div
        className={`fixed z-[999] px-8 md:px-20 w-full py-4 font-['Neue_Montreal_Regular'] flex justify-between items-center backdrop-blur-sm bg-black/20 ${
          isHidden
            ? "hidden animate__animated animate__slideOutUp"
            : "animate__animated animate__slideInDown"
        }`}
      >
        <Link className="logo" to={'https://webwisedigital.in'}>
          <img className="size-16 md:size-24" src="./logo.svg" alt="logo" />
        </Link>

        {/* Desktop Links */}
        <div className="hidden md:flex links gap-10 text-white">
          {links.map((item, index) => (
            <a
              href={item.url}
              key={index}
              className={`capitalize text-base px-2 lg:text-lg transition duration-300 font-semibold border-b-2 border-green-500 lg:px-6 hover:bg-gray-500/30 rounded-3xl p-2 ${
                index === 4 ? "ml-32" : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          ))}
        </div>

        {/* Hamburger for mobile */}
        <button
          className="md:hidden text-white p-2 focus:outline-none transition-all duration-300 ease-in-out"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          {menuOpen ? (
            <IoMdClose className="w-8 h-8" />
          ) : (
            <HiMenu className="w-8 h-8" />
          )}
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      <div
        className={`fixed inset-0 bg-black/50 z-[998] md:hidden transition-opacity duration-300 ${
          menuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setMenuOpen(false)}
      />

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 h-full w-72 bg-zinc-900 z-[999] md:hidden transform transition-transform duration-300 ease-in-out ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col h-full">
          {/* Logo Section */}
          <div className="p-6 border-b border-gray-700">
            <Link to={'https://webwisedigital.in'} onClick={() => setMenuOpen(false)}>
              <img className="h-16" src="./logo.svg" alt="logo" />
            </Link>
          </div>

          {/* Links Section */}
          <div className="flex-1 overflow-y-auto py-6">
            <div className="flex flex-col space-y-2 px-4">
              {links.map((item, index) => (
                <Link
                  to={item.url}
                  key={index}
                  className="text-white capitalize text-lg font-semibold p-3 rounded-lg transition duration-300 hover:bg-green-500/20 hover:text-green-400 flex items-center space-x-2"
                  onClick={() => setMenuOpen(false)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{item.name}</span>
                </Link>
              ))}
            </div>
          </div>

          {/* Bottom Section */}
          <div className="p-6 border-t border-gray-700">
            <a 
              href="mailto:support@webwisedigital.in"
              className="w-full bg-green-500 text-white py-3 px-4 rounded-lg text-center font-semibold hover:bg-green-600 transition duration-300"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;